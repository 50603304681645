<template>
  <div class="fav-list">
    <div
      :title="item.vote_code"
      @click="toDetail(item)"
      v-for="item in favList"
      :key="item.vote_id"
    >
      <div class="works-view">
        <image class="work-cover" :src="item.image" mode="aspectFill"></image>
        <text>{{ item.title }}</text>
        <div class="work-info">
          <div>{{ item.total_score }} <span>赞</span></div>
          <div class="fav-pannel">
            <div>{{ item.member_score }}次</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { onMounted, ref } from "vue";
import { voteRecoredList } from "../network/API";

const favList = ref([]);
onMounted(() => {
  getList();
});
async function getList() {
  let list = await voteRecoredList();
  console.log(list);
  favList.value = list;
}
</script>

<style scoped>
.fav-list {
  position: relative;
  z-index: 3;
  padding: 15px;
}

/* list */
.works-view {
  display: flex;
  flex-direction: column;
  justify-items: center;
  padding: 50px 15rpx 15rpx 15rpx;
  margin-top: -55rpx;
  position: relative;
  z-index: -1;
}
/*  */
.work-cover {
  width: 615px;
  height: 350px;
  margin-bottom: 15px;
}

.works-view text {
  font-size: 16px;
  font-weight: normal;
  color: #333;
  font-weight: 700;
}

.work-info {
  margin-top: 15px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  font-size: 20px;
  color: #b84de6;
}
.work-info span {
  font-size: 15px;
  color: #999999;
}
.work-fav {
  padding: 9px 15px;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  width: 175px;
  height: 32px;
  border-radius: 16px;
  background: linear-gradient(110deg, #ffd993 -38%, #ff6d00 61%);
  color: white;
  justify-items: center;
  align-items: center;
  font-size: 15px;
}

.work-fav image {
  width: 16px;
  height: 15px;
  margin-right: 8px;
}
.fav-pannel {
  text-align: right;
  font-size: 15px;
  color: #333;
}

.fav-date {
  font-size: 12px;
  color: #9e9e9e;
}
</style>
